/**
 * @module notification
 * @description Provide methods to display messages.
 */

/* eslint-disable compat/compat */

/**
 * @param {string} body
 * @param {string} iconUrl
 * @param {string} title
 * @returns {Notification}
 */
function spawnNotification(body, iconUrl, title) {
    /** @type {NotificationOptions} */
    const options = {
        body: body,
        icon: iconUrl || '/images/idf-logo--clear.svg',
    };

    return new Notification(title, options);
}

/**
 * @param {string} body
 * @param {string} iconUrl
 * @param {string} title
 */
export function notify(body, iconUrl, title) {
    /** @see https://caniuse.com/#feat=notifications */
    if (!('Notification' in window)) {
        alert('This browser does not support desktop notification');
    } else if (Notification.permission === 'granted') {
        spawnNotification(body, iconUrl, title);
    } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then((notificationPermission) => {
            return notificationPermission === 'granted'
                ? spawnNotification(body, iconUrl, title)
                : alert(notificationPermission);
        });
    }
}
/* eslint-enable compat/compat */
