import './../components/copyToClipboard.js';
import './../components/dropdown.js';
import './../components/tocContainer.js';
import '../admin/components/barChart.js';
import '../admin/components/memberAdminActions.js';
import '../admin/components/lineChart.js';
import '../admin/components/pieChart.js';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import {notify} from '../modules/systemNotification.js';
import 'htmx.org/dist/htmx.js';

/**
 * @see https://laravel.com/docs/broadcasting
 */
function configureBroadcasting() {
    if (!import.meta.env.VITE_PUSHER_APP_KEY) {
        return;
    }

    window.Pusher = Pusher;

    window.Echo = new Echo({
        broadcaster: import.meta.env.VITE_BROADCAST_DRIVER, // eslint-disable-line no-undef
        key: import.meta.env.VITE_PUSHER_APP_KEY,
        namespace: 'App.Events',
        cluster: 'eu',
        encrypted: import.meta.env.VITE_APP_ENV === 'production',
    });

    window.Echo.channel(`allVisitors`).listen('ArbitraryPublicBroadcastEvent', (event) => {
        notify(`${event.message}`, '', 'Hello IxDF admin!');
    });
}


/**
 * Init admin app
 */
function initApp() {
    configureBroadcasting();
}

// init page when the document is ready
document.readyState === 'loading' ? document.addEventListener('DOMContentLoaded', initApp) : initApp();
