/**
 * @component admin/components/memberAdminActions
 * @see resources/views/admin/components/memberAdminActions.blade.php
 */
import {Notification} from '../../modules/notification.js';
import {Button} from '../../modules/button.js';
import {getHttpClient} from '../../modules/httpClient.js';
import '../../components/dropdown.js';

/**
 * Do action arbitrary using ajax (get url from href attribute).
 * Possible actions:
 * - password reset;
 * - re-send welcome message;
 * - delete_account
 * - restore account
 * - delete profile picture
 *
 * @param {string} url
 * @param {string} httpVerb HTTP verb: GET|POST|DELETE|PUT|PATCH
 * @returns {Promise}
 */
async function sendAction(url, httpVerb) {
    const response = await getHttpClient()
        [httpVerb.toLowerCase()](url)
        .catch((error) => {
            const errorMessage = error.message;
            Notification.error(errorMessage);
        });

    if (response) {
        Notification.success(response.message);
    }
}

/**
 * Populate an html element via ajax with html
 * content from the server.
 *
 * @param {HTMLDialogElement} dialogElement
 */
async function populateAjaxPlaceholders(dialogElement) {
    const ajaxPlaceholder = dialogElement.querySelector('.js-ajaxPlaceholder');

    ajaxPlaceholder.textContent = 'Generating…';
    const response = await getHttpClient()
        .post(ajaxPlaceholder.dataset.url)
        .catch((error) => {
            const errorMessage = error.message;
            const errorText = `Failed to generate login link: ${errorMessage}`;
            Notification.error(errorText);
        });

    if (response) {
        ajaxPlaceholder.innerHTML = `<a href="${response.url}">Log in</a>`;
    }
}

/**
 * Init page, register event handlers
 */
function initPage() {
    document.addEventListener('click', (event) => {
        const isAjaxRequestElement = event.target.closest('.js-linkToAjaxRequest');

        if (isAjaxRequestElement) {
            event.preventDefault();
            Button.addSpinner(isAjaxRequestElement);

            sendAction(isAjaxRequestElement.href, isAjaxRequestElement.dataset.ajaxMethod).then(() => {
                Button.removeSpinner(isAjaxRequestElement);
            });
        }
    });

    document.querySelectorAll('.js-hasAjaxLoadedContent').forEach((dialogElement) => {
        dialogElement.addEventListener('open', () => {
            populateAjaxPlaceholders(dialogElement);
        }, {once: true});
    });
}

document.readyState === 'loading' ? document.addEventListener('DOMContentLoaded', initPage) : initPage();
