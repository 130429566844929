/**
 * @module BarChart
 * @description Draws bar charts
 */
import {createChart} from '../../admin/modules/chartHelper.js';
class BarChart extends HTMLElement {
    /** Called each time the element is added to the document. */
    connectedCallback() {
        const canvas = this.querySelector('canvas');
        const data = {
            labels: JSON.parse(this.getAttribute('labels')),
            datasets: JSON.parse(this.getAttribute('datasets')),
        };
        const extraConfigOptions = JSON.parse(this.getAttribute('config-options'));
        const configuration = {
            ...this._getConfig(extraConfigOptions),
            data,
        };

        const pluginOptions = JSON.parse(this.getAttribute('plugin-options'));
        pluginOptions.tooltipConfigName = this.getAttribute('tooltips');

        createChart(canvas, configuration, pluginOptions);

    }

    _getConfig(extraConfigOptions) {
        return {
            type: 'bar',
            options: {
                // Update the chart measures on resize
                responsive: true,
                plugins: {
                    legend: {
                        // Place the chart legend at the bottom
                        position: 'bottom',
                        labels: {
                            boxWidth: 20,
                            padding: 20,
                        },
                    },
                    tooltip: {
                        mode: 'index',
                        intersect: false,
                    },
                },
                elements: {
                    rectangle: {},
                },
                hover: {
                    mode: 'nearest',
                    intersect: false,
                },
                scales: {
                    x: {
                        grid: {
                            // Hide X axis (vertical) grid lines
                            display: false,
                        },
                        display: true,
                        scaleLabel: {
                            display: false,
                        },
                        stacked: true,
                    },
                    y: {
                        grid: {
                            // Hide Y axis (horizontal) grid lines
                            display: false,
                        },
                        display: false,
                        stacked: true,
                    },
                },
                ...extraConfigOptions,
            },
        };
    }
}

customElements.define('bar-chart', BarChart);
